@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply text-slate-700;
    @apply leading-8;
    @apply overflow-x-hidden;
  }
  h1 {
    @apply text-5xl;
    @apply py-2;
  }
  h2 {
    @apply text-2xl;
    @apply py-2;
  }
  h3 {
    @apply text-xl;
    @apply text-5xl;
    @apply font-light;
    @apply py-2;
  }
  h4 {
    @apply text-2xl;
    @apply py-2;
    @apply font-semibold;
  }
  a {
    @apply text-rose-600;
    @apply transition-colors;
    @apply duration-300;
  }
  a:hover {
    @apply text-rose-800;
    @apply underline;
  }
  #header-bg {
    background-image: url('assets/header.jpg');
    z-index: -1;
  }

  #header-bg::before {
    @apply absolute;
    @apply top-0;
    @apply w-screen;
    @apply h-full;
    content: '';
    background-color: rgba(44, 51, 64, 0.8);
  }
}